import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: 600,
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: "14px",
          fontSize: "14px",
          fontWeight: 500,
        },
        notchedOutline: {
          borderColor: "#06123714",
          borderWidth: "1.5px",
          borderRadius: "14px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#F46E52",
            },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#06123708",
          borderRadius: "14px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "14px",
          height: "58px",
          boxShadow: "none",
          fontSize: "16px",
          fontWeight: 700,
          minWidth: "115px",
          padding: "6px 25px",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 10px 0px #0612370F",
          borderRadius: "14px",
          padding: "8px",
          gap: "10px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          ":last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 10px 0px #0612370F",
          borderRadius: "14px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 800,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#FFFFFF",
          color: "#F24B27",
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.04)",
        },
        arrow: {
          color: "#FFFFFF",
          "::before": {
            border: "1px solid #0612370F",
          },
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
